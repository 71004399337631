import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost4 = () => {
    return (
        <Stack spacing={3}>
            <Text>Trees are an integral part of urban life in Paterson, offering aesthetic beauty, environmental
                benefits, and improved quality of life. Their role in urban settings is multifaceted and
                invaluable.</Text>
            <Heading as="h3" fontSize={'xl'}>Benefits of Urban Trees:</Heading>
            <List styleType="disc">
                <ListItem>Air Quality Improvement: Trees absorb pollutants and produce oxygen, contributing to cleaner
                    air in urban areas.</ListItem>
                <ListItem>Temperature Regulation: Through shading and evapotranspiration, trees can significantly reduce
                    urban heat islands.</ListItem>
                <ListItem>Mental Health Benefits: Studies show that urban trees and green spaces have a positive impact
                    on mental well-being and stress reduction.</ListItem>
                <ListItem>Wildlife Habitat: Urban trees provide essential habitat and food sources for a variety of
                    urban wildlife species.</ListItem>
            </List>
            <Text>Paterson Tree Service is committed to maintaining and enhancing the urban forest in Paterson. Explore
                our range of services on our <Link href='/services'>services</Link> page to learn how we can help
                support the health and growth of your urban trees.</Text>
        </Stack>
    )
}