import React from 'react';
import Slider from '../../Containers/ThemeSliders/Slider';
import {SiteData} from "../../Constants/siteData";
import ContactFormSection from "../../Components/ContactFormSection/ContactFormSection";
import {SEO} from "../../Components/SEO/SEO";
import {HomeSection} from "../../Constants/homeSection";
import {Box} from "@chakra-ui/react";
import { lazy } from 'react';
import ExperiencesWide from "../../Containers/ExperiencesWide/Experience";

const Blog = lazy(() => import('../../Containers/Blogs/Blog'));
const FunFact01 = lazy(() => import('../../Containers/Funfacts/FunFact01'));
const Banner = lazy(() => import('../../Containers/Banner'));
const Testimonial01 = lazy(() => import('../../Containers/Testimonials/Testimonial01'));
const Map = lazy(() => import('../../Components/ContactElements/Map'));

const Home01 = () => {
    return (
        <div className="main_wrapper">
            <SEO/>
            <Slider/>

            <ExperiencesWide/>
            <FunFact01/>
            <HomeSection/>
            <Testimonial01 pb='pd_btom_80'/>
            <Blog/>
            <Banner
                title={SiteData.contact.closingText()}
                heading='Call Us Anytime'
                phone={SiteData.phoneNumber}
                bannerType='banner banner_bg_color'
            />
            <ContactFormSection data={[{
                id: '1',
                iconClass: 'ion-ios-telephone-outline',
                infoBody01: 'Call us Today!',
                infoBody02: SiteData.phoneNumber,
                link: SiteData.telLink,
                cta: 'Provide some information and get a FREE quote today',
                heading: 'Get a FREE estimate'
            }]}/>
            <Box pt={10} mb={{base: 10, md: 20}}>
                <div className="container">
                    <div className="gmapbox">
                        <div id="googleMap" className="map">
                            <Map height={'200'}/>
                        </div>
                    </div>
                </div>
            </Box>
        </div>
    );
};

export default Home01;