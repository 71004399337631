import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <Stack spacing={3}>
            <Text>Trees in Paterson are susceptible to various pests and diseases, which can compromise their health and
                safety. Being proactive in identifying and managing these threats is vital to preserving the urban
                canopy.</Text>
            <Heading as="h3" fontSize={'xl'}>Key Strategies for Protecting Trees:</Heading>
            <List styleType="disc">
                <ListItem>Regular Monitoring: Keep an eye out for signs of pests or disease, such as unusual leaf
                    discoloration, dieback, or visible pests.</ListItem>
                <ListItem>Proper Pruning: Removing affected branches can help prevent the spread of disease. Check out
                    our <Link href='/tree-pruning'>tree pruning</Link> services for professional assistance.</ListItem>
                <ListItem>Cultural Practices: Good cultural practices, such as proper watering and mulching, can greatly
                    reduce the risk of pest and disease problems.</ListItem>
                <ListItem>Professional Help: If you suspect a serious issue, consult with our certified arborists. Visit
                    our <Link href='/tree-health-consulting'>tree health consulting</Link> page for expert
                    advice.</ListItem>
            </List>
            <Text>Dealing with tree pests and diseases requires expertise. Paterson Tree Service is equipped to diagnose
                and treat various tree health issues, ensuring the longevity of your urban trees.</Text>
        </Stack>
    )
}