import React from "react";
import johnsoncitymeta from '../assets/images/meridenmeta.jpg'
import bgImage from '../assets/images/overlandpark5.jpg'

import {Box, Flex, Heading, Link, List, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {BiConversation, BiCut} from "react-icons/bi";
import {GiChoppedSkull, GiLandMine, GiStumpRegrowth, GiTreeBranch, GiTreeDoor, GiTreeRoots} from "react-icons/gi";
import {FaConciergeBell, FaRegHandScissors, FaRegHandshake, FaTree} from "react-icons/fa";
import {MdEmergencyShare, MdHealthAndSafety, MdOutlineEmergency, MdOutlineNaturePeople} from "react-icons/md";
import {RiEmotionHappyLine} from "react-icons/ri";
import {AiOutlineClockCircle, AiOutlineScissor} from "react-icons/ai";

const phoneNumber = "(862) 355-3339";
const telLink = "tel:8623553339";
const phoneNumberWithDashes = "862-355-3339";

export const SiteData = {
    hoursOfOperation: "24/7",
    "city": "Paterson, NJ",
    phoneNumber,
    phoneNumberWithDashes,
    telLink,
    "buttonCtaText": () => <Flex>Ready for Expert Tree Care in Paterson? Schedule Your&nbsp;<Text
        textDecoration={'underline'} display={'inline'}>Free Consultation Today!</Text></Flex>,
    "keywords": 'Tree Care, services, professional, affordable, experienced, trimming, Paterson, NJ.',
    "footerText": 'Paterson Tree Service, All rights reserved.',
    "ogImage": johnsoncitymeta,
    "navLinks": [
        {
            "title": 'Home',
            "href": '/'
        },
        {
            "title": 'Services',
            "href": '/services'
        },
        {
            "title": 'Blog',
            "href": '/blog'
        },
        {
            "title": 'About',
            "href": '/about'
        },
        {
            "title": 'Contact Us',
            "href": '/contact'
        },
        {
            "title": 'Careers',
            "href": '/careers',
            "hideFromTopNav": true
        },
    ],
    "signupForm": {
        "intro": () => <Text>Welcome to Paterson Tree Service, where exceptional tree care meets the unique needs of
            Paterson, NJ. Our team of certified arborists offers a full range of tree services. Reach out to us at <Link
                href={'tel:8623553339'} textDecoration={'underline'}>(862) 355-3339</Link> for expert tree care.</Text>,
        "servicesTitle": 'Our Services:',
    },
    "homepage": {
        "bgImage": bgImage,
        "url": 'https://www.patersonnjtreeservice.com/',
        "metaData": {
            "title": 'Paterson Tree Service: Expert Tree Care in Paterson, NJ | Call (862) 355-3339',
            "description": 'Professional tree care services in Paterson, NJ. Paterson Tree Service offers expert solutions for tree trimming, removal, and maintenance. Contact us for superior tree care.'
        },
        "h1": 'Professional Tree Care Services in Paterson, NJ',
        "subtitle": 'Your Trusted Partner for Comprehensive Tree Care - Trimming, Removal, Health Consulting, and More',
        "servicesTitle": 'Why Choose Paterson Tree Service',
        "values": [
            {
                "id": 0,
                "number": "10,000+",
                "name": "Trees Serviced",
                "icon": GiTreeBranch,
            },
            {
                "id": 1,
                "number": "Top Rated",
                "name": "Client Satisfaction",
                "icon": RiEmotionHappyLine,
            },
            {
                "id": 2,
                "number": "24/7",
                "name": "Emergency Response",
                "icon": AiOutlineClockCircle,
            },
            {
                "id": 3,
                "number": "20+ Years",
                "name": "Experience in Tree Care",
                "icon": FaRegHandshake,
            },
        ],
        "services": [
            {
                "id": '0',
                "text": () => <Text><b>Highly Skilled Arborists:</b> Our certified arborists are well-versed in
                    Paterson’s unique tree species and landscaping needs, offering unparalleled expertise.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Safe and Efficient Tree Services:</b> We adhere to strict safety standards and
                    use advanced technology to ensure a safe and efficient service for every client in Paterson.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Competitive Pricing:</b> Offering affordable rates without compromising on
                    quality, we provide value-packed tree care services in Paterson.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Comprehensive Tree Care:</b> From routine maintenance to emergency services, our
                    wide range of services covers all aspects of tree care.</Text>
            },
            {
                "id": '4',
                "text": () => <Text><b>Local Expertise:</b> As a team rooted in Paterson, NJ, we bring a wealth of local
                    experience and knowledge, ensuring top-quality service for our community.</Text>
            }
        ],
        "content": [
            <>
                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Paterson Tree Service: Leading Tree Care in Paterson,
                        NJ</Heading>
                    Welcome to Paterson Tree Service, your local experts in tree care, serving the Paterson community
                    with dedication and professionalism. Our services range from tree trimming and stump grinding to
                    comprehensive tree maintenance and health consulting.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Our Array of Tree Care Services</Heading>
                    We cater to a variety of tree care needs:
                    <UnorderedList>
                        <ListItem>Tree Trimming: Expert trimming and pruning for tree health and aesthetics.</ListItem>
                        <ListItem>Stump Grinding: Effective removal of unsightly or hazardous stumps.</ListItem>
                        <ListItem>Tree Maintenance: Diverse tree health and maintenance services for optimal
                            care.</ListItem>
                        <ListItem>Tree Planting: Careful planting and transplanting services for healthy tree
                            growth.</ListItem>
                        <ListItem>Tree Health Consulting: Professional advice from certified arborists for informed tree
                            care decisions.</ListItem>
                        <ListItem>Emergency Tree Care: Prompt and reliable services for urgent <a
                            href={"https://www.oregoncitytreecare.com/"} target={"_blank"} rel={"noreferrer"}>tree
                            service</a> needs.</ListItem>
                        <ListItem>Arborist Consultations: Expert consultations focusing on tree health and risk
                            management.</ListItem>
                        <ListItem>Land Clearing: Efficient services for <a
                            href={"https://www.hounslowtreesurgeons.com/"} target={'_blank'} rel={'noreferrer'}>tree
                            removal</a> and property
                            preparation.</ListItem>
                    </UnorderedList>
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Committed to Quality and Eco-Friendly Practices</Heading>
                    At Paterson Tree Service, we are dedicated to providing high-quality, eco-friendly <a
                    href={"https://www.puyalluptreeservice.net/"} target={"_blank"} rel={"noreferrer"}>tree service</a>.
                    Our goal is to enhance the health and beauty of your trees, contributing positively to the Paterson
                    community.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Why Paterson Residents Trust Our Tree Service</Heading>
                    Our commitment to expertise, environmental care, timely service, and customized solutions makes us a
                    preferred choice in Paterson, NJ.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Contact Paterson Tree Service Today</Heading>
                    For expert <a href={'https://newrochelletreeservice.com'} rel={'noreferrer'} target={'_blank'}>tree
                    care services</a> in Paterson, NJ, call us at (862) 355-3339. Receive a personalized
                    quote and discover why we are a top choice for tree care in the region.
                </Text>
            </>
        ],
        "footerHeading": 'Contact Paterson Tree Service',
        "footerText": () => <>For top-notch tree services in Paterson and the surrounding areas, get in touch with us.
            Call or fill out our online contact form for non-emergency inquiries. We're here for all your tree care
            needs!</>,
        "footerText2": 'Your Trees’ Health and Beauty are Our Priority',
        "servicesHeader": 'Our Extensive Range of Tree Services for Paterson’s Unique Arboricultural Needs',
        "servicesSubtitle": () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Explore our suite of services –
            from specialized tree care to emergency solutions, Paterson Tree Service is your go-to for all tree-related
            needs in Paterson, NJ.</Text>,
        "featuredServices": [
            {
                "name": 'Tree Trimming',
                "description": 'Our tree trimming service ensures the health and beauty of your trees. We specialize in trimming techniques that enhance tree structure and appearance, tailored to each species found in Paterson.',
                "icon": BiCut,
                "path": '/tree-trimming',
                "linkTitle": 'Tree Trimming'
            },
            {
                "name": 'Stump Grinding',
                "description": 'Efficiently remove tree stumps from your property with our stump grinding service. We ensure a thorough job, leaving your landscape clean and ready for new growth or development.',
                "icon": GiTreeRoots,
                "path": '/stump-grinding',
                "linkTitle": 'Stump Grinding'
            },
            {
                "name": 'Tree Maintenance',
                "description": 'Our comprehensive tree maintenance services include health assessments, pruning, and disease management, ensuring your trees stay healthy and vibrant throughout the year.',
                "icon": FaTree,
                "path": '/tree-health-consulting',
                "linkTitle": 'Tree Maintenance'
            },
            {
                "name": 'Tree Planting and Transplanting',
                "description": 'Whether you’re adding new trees to your landscape or need to relocate existing ones, our planting and transplanting services ensure the health and success of your trees in their new environment.',
                "icon": GiTreeDoor,
                "path": '/tree-planting',
                "linkTitle": 'Tree Planting and Transplanting'
            },
            {
                "name": 'Tree Health Consulting',
                "description": 'Our certified arborists provide expert tree health consulting, offering insights and solutions for tree care, disease management, and overall maintenance tailored to Paterson’s unique climate.',
                "icon": FaConciergeBell,
                "path": '/tree-health-consulting',
                "linkTitle": 'Tree Health Consulting'
            },
            {
                "name": 'Emergency Tree Services',
                "description": 'In the event of a storm or other emergencies, our team is ready to respond swiftly. We offer reliable emergency tree services to address immediate risks and prevent potential hazards.',
                "icon": MdOutlineEmergency,
                "path": '/emergency-tree-services',
                "linkTitle": 'Emergency Tree Services'
            },
            {
                "name": 'Arborist Consultations',
                "description": 'Gain valuable insights from our professional arborists. We provide expert consultations on tree health, risk assessment, and management strategies, helping you make informed decisions about your trees.',
                "icon": MdOutlineNaturePeople,
                "path": '/arborist-consultations',
                "linkTitle": 'Arborist Consultations'
            },
            {
                "name": 'Land Clearing',
                "description": 'Our land clearing services are designed for efficiency and environmental responsibility. Whether for construction or landscaping, we prepare your land with attention to detail and care for the ecosystem.',
                "icon": FaRegHandScissors,
                "path": '/land-clearing',
                "linkTitle": 'Land Clearing'
            }
        ]
    },
    "careers": {
        "url": "https://www.patersonnjtreeservice.com/careers/",
        "metaData": {
            "title": "Join Our Team - Paterson Tree Service Careers in Paterson, NJ",
            "description": "Looking for a fulfilling career in tree care? Check out Paterson Tree Service in Paterson, NJ. We're all about great teamwork and providing top-notch tree services."
        },
        "h1": "Become Part of the Paterson Tree Service Family",
        "h2": [
            "Exciting Tree Care Opportunities Await in Paterson",
            "Grow With Us: Our Dedication to Your Development",
            "Why Our Team is the Heart of Paterson Tree Service"
        ],
        "headingText": {
            "whyJoin": "If you're passionate about trees and the environment, you'll fit right in here at Paterson, NJ. We're not just a team, we're a family, dedicated to top-class tree services.",
            "openings": "Looking for arborists, tree climbers, or supportive roles? We welcome anyone who shares our enthusiasm for tree care and outstanding customer service.",
            "commitment": "Here at Paterson Tree Service, we're big on nurturing our team. Expect training, fair pay, and a work environment that's both challenging and rewarding."
        },
        "content": [
            <Text>At Paterson Tree Service, we've got various roles to fill in the exciting world of arboriculture. Join
                us in Paterson, NJ, and be a part of a team that's committed to tree care excellence.</Text>,
            <Text>No matter if you're just starting out or you've been in the tree care game for years, we have a spot
                for you. We value our team members and support each other in our work.</Text>,
            <Text>We're all about learning and growing together. From comprehensive training to competitive pay, we
                provide everything you need for a safe and fulfilling career.</Text>,
            <Text>Our pride comes from serving the Paterson community. We're dedicated to being the best in urban
                forestry and landscape management, and we want you to join us in this journey.</Text>,
            <Text>Keen on a career in tree care right here in Paterson, NJ? Take a look at our current job openings. We
                have positions ranging from hands-on tree technicians to customer service experts.</Text>,
            <Text>Ready to take the plunge? Apply now and become a key player in Paterson's tree care scene. Head over
                to <a href='https://www.patersonnjtreeservice.com/careers/'>our careers page</a> for more info and to
                send
                in your application.</Text>
        ]
    },
    "about": {
        "url": "https://www.patersonnjtreeservice.com/about/",
        "metaData": {
            "title": "About Paterson Tree Service - Leading Tree Care Experts in Paterson, NJ",
            "description": "Discover Paterson Tree Service's commitment to superior tree care. Our experienced team provides exceptional services in Paterson, NJ, with a focus on safety and customer satisfaction."
        },
        "h1": "Your Trusted Tree Care Experts in Paterson, NJ",
        "h2": [
            "A Legacy of Excellence in Tree Services",
            "Meet Our Team: Skilled Arborists and Tree Care Professionals",
            "Dedicated to Sustainable and Safe Tree Care Practices"
        ],
        "headingText": {
            "commitment": "Paterson Tree Service is committed to delivering top-tier tree care services, enhancing the beauty and health of Paterson's urban forest while ensuring the safety of our community.",
            "team": "Our team consists of passionate, certified arborists and experienced tree care professionals. We are dedicated to providing the highest standard of service and arboricultural expertise.",
            "services": "Offering a wide range of tree care services, we specialize in tree pruning, removal, health assessments, and emergency services, using state-of-the-art equipment and sustainable practices."
        },
        "services": [
            {
                "id": "0",
                "title": "Tree Pruning & Trimming",
                "href": "/tree-pruning",
                "icon": AiOutlineScissor,
                "description": (
                    <Text>
                        Expert pruning and trimming services to maintain the health and appearance of trees, enhancing
                        their structural integrity and aesthetic value.
                    </Text>
                ),
            },
            {
                "id": "1",
                "title": "Safe Tree Removal",
                "href": "/tree-removal",
                "icon": GiChoppedSkull,
                "description": (
                    <Text>
                        Professional tree removal services for hazardous, diseased, or inconveniently located trees,
                        ensuring safety and compliance with local regulations.
                    </Text>
                ),
            },
            {
                "id": "2",
                "title": "Stump Grinding & Removal",
                "href": "/stump-grinding",
                "icon": GiStumpRegrowth,
                "description": (
                    <Text>
                        Efficient stump grinding and removal to clear your landscape, reducing safety hazards and paving
                        the way for new growth.
                    </Text>
                ),
            },
            {
                "id": "3",
                "title": "24/7 Emergency Tree Services",
                "href": "/emergency-tree-services",
                "icon": MdEmergencyShare,
                "description": (
                    <Text>
                        Rapid response to emergency tree situations, including storm damage and fallen trees, to ensure
                        public safety and property protection.
                    </Text>
                ),
            },
            {
                "id": "4",
                "title": "Tree Health Assessments",
                "href": "/tree-health-assessments",
                "icon": MdHealthAndSafety,
                "description": (
                    <Text>
                        Comprehensive tree health assessments conducted by certified arborists to identify and address
                        any signs of disease, pest infestation, or environmental stress.
                    </Text>
                ),
            },
            {
                "id": "5",
                "title": "Arborist Consultations",
                "href": "/arborist-consultations",
                "icon": BiConversation,
                "description": (
                    <Text>
                        Professional consultations offering expert advice on tree care, planting strategies, and
                        maintenance plans tailored to individual tree species and landscape requirements.
                    </Text>
                ),
            },
            {
                "id": "6",
                "title": "Land Clearing Services",
                "href": "/land-clearing",
                "icon": GiLandMine,
                "description": (
                    <Text>
                        Comprehensive land clearing services for construction, landscaping, or property development
                        projects, ensuring efficient and environmentally responsible execution.
                    </Text>
                ),
            }
        ]
    },
    "testimonials": {
        "h1": 'Client Testimonials',
        "heading": 'Real Feedback from Our Satisfied Customers in Paterson, NJ',
        "testimonials": [
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a0',
                "name": 'John Stanner',
                "title": 'Paterson Resident',
                "quote": 'I was impressed with the professionalism and expertise of Paterson Tree Service. The team did an excellent job trimming our oak trees and keeping them healthy. The results were fantastic!'
            },
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a045d',
                "name": 'Jane Smith',
                "title": 'Local Business Owner',
                "quote": 'After a severe storm, we needed emergency tree removal, and Paterson Tree Service responded quickly. They handled the situation safely and efficiently, which was a huge relief.'
            },
            {
                "avatarUrl": 'https://i.pravatar.cc/150?u=a0425d',
                "name": 'Mike Johnson',
                "title": 'Homeowner',
                "quote": 'The arborist consultation from Paterson Tree Service helped us understand how to better care for our trees. Their advice was invaluable, and our garden has never looked better.'
            }
        ]
    },
    "services": {
        "metaData": {
            "title": 'Expert Tree Care Services in Paterson, NJ | Paterson Tree Service',
            "description": 'Discover our range of tree care services in Paterson, NJ. From tree trimming to comprehensive tree health maintenance, Paterson Tree Service offers expert solutions.'
        },
        "h1": 'Professional Tree Services in Paterson, NJ',
        "h2": 'Our Comprehensive Tree Care Solutions',
        "subtext": 'Paterson Tree Service provides a variety of expert tree care services, focusing on the health and aesthetics of your landscape at competitive rates.',
        "footerHeading": 'Enhance Your Landscape with Our Expertise',
        "footerText2": () => <>Contact us at <Link href={'tel:8623553339'}>{'862-355-3339'}</Link> for a free quote.
            Experience the difference with Paterson Tree Service today!</>,
        "footerText": () => <>At Paterson Tree Service, your trees are our primary concern. For superior tree care in
            Paterson, NJ, we're just a call away.</>,
        "content": <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">Paterson Tree Service: Your Tree Care Specialist</Heading>
                <Text>Our team is dedicated to providing the best tree care services in Paterson, NJ. We offer a wide
                    range of services to meet all your tree care needs, from routine maintenance to emergency
                    responses.</Text>

                <Heading as="h3" size="lg">Our Range of Tree Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Tree Trimming and Pruning:</b> Our expert trimming and pruning services help
                        maintain the health and beauty of your trees, enhancing their growth and
                        appearance.</Text></ListItem>
                    <ListItem><Text><b>Emergency Tree Services:</b> We are available 24/7 to respond to any tree
                        emergencies, ensuring quick and safe resolution to protect your property.</Text></ListItem>
                    <ListItem><Text><b>Stump Grinding and Removal:</b> Our stump grinding services efficiently remove
                        tree stumps, enhancing the safety and aesthetics of your landscape.</Text></ListItem>
                    <ListItem><Text><b>Tree Health Assessments:</b> We provide comprehensive health assessments to
                        diagnose and treat any tree diseases or pest infestations.</Text></ListItem>
                    <ListItem><Text><b>Arborist Consultations:</b> Our certified arborists are available for
                        consultations, offering professional advice on tree care and maintenance
                        strategies.</Text></ListItem>
                    <ListItem><Text><b>Land Clearing:</b> We offer land clearing services for construction, landscaping,
                        or agricultural projects, focusing on efficiency and environmental
                        responsibility.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Customized Tree Care Solutions</Heading>
                <Text>We understand that each tree and landscape is unique. Our team offers customized tree care
                    solutions to ensure the best results for your specific needs in Paterson, NJ.</Text>

                <Heading as="h3" size="lg">Safety and Sustainability</Heading>
                <Text>At Paterson Tree Service, we prioritize safety and sustainable practices in all our services,
                    ensuring the well-being of your trees and the environment.</Text>

                <Heading as="h3" size="lg">Contact Us for Professional Tree Care</Heading>
                <Text>For expert tree care services in Paterson, NJ, trust Paterson Tree Service. Contact us today
                    at <Link href={'tel:8623553339'}>{'862-355-3339'}</Link> for a consultation or to schedule our
                    services.</Text>
            </Stack>
        </Box>,
        "services": [
            {
                "id": '0',
                "text": () => <Text><b>Tree Pruning and Trimming:</b> Professional pruning and trimming services to keep
                    your trees healthy and aesthetically pleasing, tailored to the tree species in Paterson, NJ.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Stump Grinding:</b> Efficient removal of stumps to improve the safety and
                    appearance of your landscape, using advanced grinding techniques.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Tree Removal:</b> Safe and responsible removal of trees that are hazardous,
                    damaged, or otherwise inappropriate for their location.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Tree Health Consulting:</b> Expert assessment and advice on tree health,
                    including disease management and pest control, by our certified arborists.</Text>
            },
            {
                "id": '4',
                "text": () => <Text><b>Emergency Tree Services:</b> Rapid response to tree emergencies such as storm
                    damage, ensuring the safety of your property and its occupants.</Text>
            },
            {
                "id": '5',
                "text": () => <Text><b>Arborist Consultations:</b> Professional guidance on tree care and management
                    from our experienced arborists, tailored to your specific needs.</Text>
            },
            {
                "id": '6',
                "text": () => <Text><b>Land Clearing:</b> Comprehensive land clearing services for property development,
                    landscaping, or agricultural use, done with an eye toward eco-friendliness.</Text>
            },
        ],
        "whyh2": 'Why Paterson Tree Service?',
        "whySubtext": () => <>Our team at Paterson Tree Service is dedicated to providing the highest quality tree care
            services in Paterson. Contact us at <Link href={'tel:8623553339'}>{'862-355-3339'}</Link> to experience our
            commitment to excellence.</>,
        "whyServices": [
            {
                "id": '0',
                "text": () => <Text><b>Experienced Professionals:</b> Our team's expertise in arboriculture ensures
                    top-quality service for every tree care project.</Text>
            },
            {
                "id": '1',
                "text": () => <Text><b>Commitment to Customer Satisfaction:</b> We prioritize our customers’ needs,
                    ensuring satisfaction with every service we provide.</Text>
            },
            {
                "id": '2',
                "text": () => <Text><b>Local Knowledge:</b> Our deep understanding of Paterson's environment and tree
                    species allows us to offer specialized and effective tree care services.</Text>
            },
            {
                "id": '3',
                "text": () => <Text><b>Quality and Safety:</b> Adhering to the highest standards of quality and safety,
                    we ensure the well-being of your trees and property.</Text>
            },
        ]
    },
    "contact": {
        "url": 'https://www.patersonnjtreeservice.com/contact/',
        "metaData": {
            "title": 'Contact Us - Paterson Tree Service | Professional Tree Care Services',
            "description": 'Reach out to Paterson Tree Service for all your tree care needs in Paterson, NJ. Our team is ready to provide expert advice and services.'
        },
        "h1": 'Get in Touch with Paterson Tree Service',
        "subtitle": 'Contact us today for professional and reliable tree care services in Paterson, NJ. Our team is eager to assist with all your tree care requirements.',
        "sectionHeading": 'Your Tree Care Experts in Paterson',
        "signUpDescription": 'Fill out our contact form below or give us a call. We’re here to answer your questions and provide top-notch tree care solutions.',
        "feedbackText": 'We value your feedback and inquiries. Let us know how we can help you with your tree care needs in Paterson.',
        "closingText": () => <>Paterson Tree Service is dedicated to enhancing the beauty and safety of your property
            with professional tree care services.</>,
        "valueProps": [
            {
                "title": 'Expert Tree Care',
                "description": 'Our team of certified arborists and experienced tree care professionals is equipped to handle all aspects of tree care, ensuring the best results for your property.'
            },
            {
                "title": 'Comprehensive Services',
                "description": 'From routine maintenance to emergency tree removal, we offer a wide range of services to keep your trees healthy and your property safe.'
            },
            {
                "title": 'Customer Satisfaction',
                "description": 'We prioritize your satisfaction, offering personalized service and attention to detail in every project we undertake.'
            }
        ]
    },
    "blog": {
        "url": 'https://www.patersonnjtreeservice.com/blog/',
        "metaData": {
            "title": 'New Rochelle Tree Service Blog: Tree Care Insights and Tips',
            "description": 'Expert advice and latest trends in tree care from New Rochelle Tree Service. Discover helpful tips and insights on maintaining healthy, beautiful trees in New Rochelle, NY.',
        },
        "h1": 'Latest from New Rochelle Tree Service Blog',
        "h2": 'Informative Articles and Tree Care Tips',
        "heading": 'Our blog offers valuable information and advice on tree care, keeping you informed on the best practices for maintaining the health and beauty of your trees in New Rochelle, NY.',
        "posts": [
            {
                "id": '1',
                "title": 'Maximizing Urban Tree Health in Paterson: Best Practices',
                "metaTitle": 'Urban Tree Health Management in Paterson | Paterson Tree Service Blog',
                "excerpt": 'Learn how to maintain and enhance the health of urban trees in Paterson, amidst environmental challenges and urban constraints.',
                "slug": 'urban-tree-health-paterson',
                "tags": ['Urban Tree Care', 'Tree Health', 'Paterson'],
                "metaDescription": 'This guide focuses on effective strategies for maintaining tree health in urban areas like Paterson, NJ, addressing specific environmental challenges.'
            },
            {
                "id": '2',
                "title": 'The Importance of Seasonal Tree Care in Paterson',
                "metaTitle": 'Seasonal Tree Care Tips | Paterson Tree Service Blog',
                "excerpt": 'Explore essential seasonal tree care tips to keep your trees in Paterson thriving throughout the year, from the heat of summer to the chill of winter.',
                "slug": 'seasonal-tree-care-paterson',
                "tags": ['Seasonal Tree Care', 'Year-Round Maintenance', 'Paterson'],
                "metaDescription": 'Discover how seasonal changes affect trees in Paterson, NJ, and learn the best practices for tree care throughout the year.'
            },
            {
                "id": '3',
                "title": 'Safeguarding Your Trees from Pests and Diseases in Paterson',
                "metaTitle": 'Protecting Paterson Trees from Pests | Paterson Tree Service Blog',
                "excerpt": 'Understand common tree pests and diseases in Paterson and learn effective methods to protect your trees from these threats.',
                "slug": 'tree-pests-diseases-paterson',
                "tags": ['Tree Pests', 'Tree Diseases', 'Paterson'],
                "metaDescription": 'This article offers insights into identifying and combating common pests and diseases that affect trees in Paterson, NJ.'
            },
            {
                "id": '4',
                "title": 'The Role of Trees in Enhancing Urban Life in Paterson',
                "metaTitle": 'Trees and Urban Well-being in Paterson | Paterson Tree Service Blog',
                "excerpt": 'Discover how urban trees contribute to the well-being of communities in Paterson, and why investing in tree care is crucial for urban environments.',
                "slug": 'urban-trees-well-being-paterson',
                "tags": ['Urban Trees', 'Community Well-being', 'Paterson'],
                "metaDescription": 'Explore the multiple benefits that urban trees bring to the city of Paterson, NJ, and the importance of proper tree care in urban settings.'
            }
        ]
    },
    "treePruning": {
        "url": 'https://www.patersonnjtreeservice.com/tree-pruning',
        "metaData": {
            "title": 'Professional Tree Pruning in Paterson, NJ | Paterson Tree Service',
            "description": 'Enhance the health and appearance of your trees with our expert tree pruning services in Paterson, NJ.'
        },
        "h1": 'Expert Tree Pruning Services in Paterson, NJ',
        "subtitle": 'Paterson Tree Service offers specialized tree pruning to promote tree health and aesthetics, tailored to the specific needs of Paterson’s diverse tree species.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Pruning Matters</Heading>
                <Text>Regular pruning maintains tree health, encourages growth, and enhances the beauty of your
                    landscape. It's also essential for safety, removing potentially dangerous overhanging limbs.</Text>

                <Heading as={'h3'}>Our Pruning Services</Heading>
                <Text>We offer comprehensive pruning services, including crown cleaning, thinning, raising, and
                    reduction. Our team is skilled in advanced pruning techniques suitable for all tree types in
                    Paterson.</Text>

                <Heading as={'h3'}>Schedule Your Tree Pruning</Heading>
                <Text>Contact us for professional tree pruning tailored to your needs. Our arborists are ready to help
                    you maintain the health and safety of your trees. Call <Link
                        href={'tel:8623553339'}>862-355-3339</Link> for a consultation.</Text>
            </Stack>
        </Box>
    },
    "treeRemoval": {
        "url": 'https://www.patersonnjtreeservice.com/tree-removal',
        "metaData": {
            "title": 'Safe and Efficient Tree Removal in Paterson, NJ | Paterson Tree Service',
            "description": 'Need to remove a tree in Paterson, NJ? Trust our experts for safe and efficient tree removal services.'
        },
        "h1": 'Professional Tree Removal Services in Paterson, NJ',
        "subtitle": 'Paterson Tree Service provides safe, efficient tree removal services, using state-of-the-art equipment and techniques to handle any tree removal, big or small.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>When Removal is Necessary</Heading>
                <Text>Tree removal is often required for dead or diseased trees, those posing safety hazards, or trees
                    obstructing construction. We assess each situation to recommend the best course of action.</Text>

                <Heading as={'h3'}>Our Removal Process</Heading>
                <Text>We prioritize safety and efficiency in our removal process, using advanced equipment to minimize
                    impact on your property. Our team handles everything from cutting down the tree to stump removal and
                    site cleanup.</Text>

                <Heading as={'h3'}>Contact Us for Tree Removal</Heading>
                <Text>If you have a tree that needs removal in Paterson, NJ, contact us for expert service. Call <Link
                    href={'tel:8623553339'}>862-355-3339</Link> to schedule a removal.</Text>
            </Stack>
        </Box>
    },
    "treeTrimming": {
        "url": 'https://www.patersonnjtreeservice.com/tree-trimming',
        "metaData": {
            "title": 'Expert Tree Trimming in Paterson, NJ | Paterson Tree Service',
            "description": 'Keep your trees healthy and beautiful with our professional tree trimming services in Paterson, NJ.'
        },
        "h1": 'Professional Tree Trimming Services in Paterson, NJ',
        "subtitle": 'Paterson Tree Service offers expert tree trimming services to enhance the health, safety, and aesthetic appeal of your trees, tailored to Paterson’s urban landscape.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Benefits of Tree Trimming</Heading>
                <Text>Regular trimming is crucial for tree health, preventing the spread of disease and pests. It also
                    improves the overall appearance and structure of your trees.</Text>

                <Heading as={'h3'}>Our Trimming Techniques</Heading>
                <Text>We utilize various trimming techniques like deadwooding, canopy lifting, and shaping to suit
                    different tree species and individual needs of our clients in Paterson.</Text>

                <Heading as={'h3'}>Schedule Tree Trimming</Heading>
                <Text>For top-quality tree trimming services in Paterson, NJ, get in touch with us. Our team is ready to
                    enhance the beauty and health of your trees. Call <Link
                        href={'tel:8623553339'}>862-355-3339</Link> for an appointment.</Text>
            </Stack>
        </Box>
    },
    "stumpGrinding": {
        "url": 'https://www.patersonnjtreeservice.com/stump-grinding',
        "metaData": {
            "title": 'Efficient Stump Grinding in Paterson, NJ | Paterson Tree Service',
            "description": 'Remove unwanted stumps with our stump grinding services in Paterson, NJ. Enhance your property’s appearance and safety.'
        },
        "h1": 'Stump Grinding Services in Paterson, NJ',
        "subtitle": 'Paterson Tree Service offers efficient stump grinding services to safely remove tree stumps and enhance the aesthetics and functionality of your property.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Stump Grinding is Important</Heading>
                <Text>Stump grinding is essential for removing trip hazards, deterring pests, and preparing your land
                    for new landscaping projects.</Text>

                <Heading as={'h3'}>Our Stump Grinding Process</Heading>
                <Text>Our team uses advanced equipment to grind stumps below the ground level, ensuring complete removal
                    without damaging your property.</Text>

                <Heading as={'h3'}>Contact Us for Stump Grinding</Heading>
                <Text>Need to remove a tree stump in Paterson, NJ? Contact Paterson Tree Service for efficient and safe
                    stump grinding. Call <Link href={'tel:8623553339'}>862-355-3339</Link> for a free estimate.</Text>
            </Stack>
        </Box>
    },
    "treeHealthConsulting": {
        "url": 'https://www.patersonnjtreeservice.com/tree-health-consulting',
        "metaData": {
            "title": 'Professional Tree Health Consulting in Paterson, NJ | Paterson Tree Service',
            "description": 'Ensure the health of your trees with our tree health consulting services in Paterson, NJ.'
        },
        "h1": 'Tree Health Consulting Services in Paterson, NJ',
        "subtitle": 'Our certified arborists provide comprehensive tree health assessments and consulting to diagnose, treat, and maintain the health of your trees.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Tree Health Consulting Matters</Heading>
                <Text>Regular health assessments can detect early signs of disease or pest infestation, helping to
                    prolong the life of your trees.</Text>

                <Heading as={'h3'}>Our Consulting Services</Heading>
                <Text>We offer detailed health assessments, personalized care plans, and expert advice on managing tree
                    diseases and pests.</Text>

                <Heading as={'h3'}>Schedule a Consultation</Heading>
                <Text>Contact Paterson Tree Service for professional tree health consulting in Paterson, NJ. Our
                    arborists are ready to assist you. Call <Link href={'tel:8623553339'}>862-355-3339</Link>.</Text>
            </Stack>
        </Box>
    },
    "landClearing": {
        "url": 'https://www.patersonnjtreeservice.com/land-clearing',
        "metaData": {
            "title": 'Efficient Land Clearing Services in Paterson, NJ | Paterson Tree Service',
            "description": 'Prepare your property for new projects with our professional land clearing services in Paterson, NJ.'
        },
        "h1": 'Land Clearing Services in Paterson, NJ',
        "subtitle": 'Paterson Tree Service offers comprehensive land clearing services, preparing your land for construction, landscaping, or other projects with efficiency and care.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Why Choose Our Land Clearing Services</Heading>
                <Text>Our experienced team uses the latest equipment and techniques to clear land safely and
                    effectively, minimizing environmental impact.</Text>

                <Heading as={'h3'}>Our Approach to Land Clearing</Heading>
                <Text>We handle everything from tree and brush removal to stump grinding and site cleanup, ensuring a
                    smooth process for your land development projects.</Text>

                <Heading as={'h3'}>Contact Us for Land Clearing</Heading>
                <Text>Need land clearing in Paterson, NJ? Contact Paterson Tree Service for efficient and responsible
                    service. Call <Link href={'tel:8623553339'}>862-355-3339</Link> to get started.</Text>
            </Stack>
        </Box>
    },
    "arboristConsulting": {
        "url": 'https://www.patersonnjtreeservice.com/arborist-consultations',
        "metaData": {
            "title": 'Expert Arborist Consultations in Paterson, NJ | Paterson Tree Service',
            "description": 'Get professional advice on tree care and maintenance from our certified arborists in Paterson, NJ.'
        },
        "h1": 'Arborist Consultation Services in Paterson, NJ',
        "subtitle": 'Our certified arborists offer expert consultations on tree care, health, and management, providing tailored advice for the specific needs of trees in Paterson.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>The Importance of Arborist Consultations</Heading>
                <Text>Consultations with a certified arborist can provide insights into the best care practices for your
                    trees, helping to ensure their long-term health and vitality.</Text>

                <Heading as={'h3'}>What Our Consultations Include</Heading>
                <Text>We offer advice on tree health, planting, pruning, disease management, and risk assessment to help
                    you make informed decisions about your trees.</Text>

                <Heading as={'h3'}>Book an Arborist Consultation</Heading>
                <Text>For expert arborist advice in Paterson, NJ, contact Paterson Tree Service. Our team is ready to
                    assist you. Call <Link href={'tel:8623553339'}>862-355-3339</Link> to schedule a
                    consultation.</Text>
            </Stack>
        </Box>
    },
    "treePlanting": {
        "url": 'https://www.patersonnjtreeservice.com/tree-planting',
        "metaData": {
            "title": 'Professional Tree Planting Services in Paterson, NJ | Paterson Tree Service',
            "description": 'Enhance your landscape with our tree planting services in Paterson, NJ. Expert care for the healthy growth of your trees.'
        },
        "h1": 'Tree Planting Services in Paterson, NJ',
        "subtitle": 'Paterson Tree Service provides expert tree planting and transplanting services, ensuring healthy growth and proper establishment of trees in your landscape.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Benefits of Professional Tree Planting</Heading>
                <Text>Professional tree planting ensures your new trees are correctly placed and planted, promoting
                    healthy growth and reducing future maintenance issues.</Text>

                <Heading as={'h3'}>Our Tree Planting Process</Heading>
                <Text>We consider factors like soil condition, sunlight, and tree species to choose the best location
                    and planting method for each tree, ensuring optimal health and growth.</Text>

                <Heading as={'h3'}>Schedule Tree Planting</Heading>
                <Text>Looking to add new trees to your property in Paterson, NJ? Contact Paterson Tree Service for
                    professional tree planting services. Call <Link href={'tel:8623553339'}>862-355-3339</Link> to get
                    started.</Text>
            </Stack>
        </Box>
    },
    "emergencyTreeServices": {
        "url": 'https://www.patersonnjtreeservice.com/emergency-tree-services',
        "metaData": {
            "title": '24/7 Emergency Tree Services in Paterson, NJ | Paterson Tree Service',
            "description": 'Immediate response for emergency tree services in Paterson, NJ. Quick and safe resolution of urgent tree care needs.'
        },
        "h1": 'Emergency Tree Services in Paterson, NJ',
        "subtitle": 'Paterson Tree Service provides rapid and reliable emergency tree services, ensuring the safety and integrity of your property during urgent situations.',
        "body": <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Emergency Response</Heading>
                <Text>We are available 24/7 to respond to tree emergencies, including storm damage, fallen trees, and
                    hazardous situations, prioritizing quick and safe resolution.</Text>

                <Heading as={'h3'}>Handling Tree Emergencies</Heading>
                <Text>Our team is equipped with the necessary tools and expertise to handle any emergency tree
                    situation, minimizing risks and damages to your property.</Text>

                <Heading as={'h3'}>Contact Us for Urgent Tree Care</Heading>
                <Text>If you face a tree emergency in Paterson, NJ, don't hesitate to call Paterson Tree Service. Reach
                    us at <Link href={'tel:8623553339'}>862-355-3339</Link> for immediate assistance.</Text>
            </Stack>
        </Box>
    },
    "404": {
        "metaData": {
            "title": '404 Page Not Found - Paterson Tree Service | Professional Tree Care',
            "description": 'The page you’re looking for might have been removed, had its name changed, or is temporarily unavailable. Paterson Tree Service offers comprehensive tree care services.'
        },
        "h1": '404 - Page Not Found',
        "subtitle": 'Oops! We can’t seem to find the page you’re looking for.',
        "p": 'If you’re looking for professional tree care services in Paterson, NJ, head back to our <Link href="/">homepage</Link> or explore our <Link href="/services">services</Link> to find what you need.'
    }
}