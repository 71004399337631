import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost1 = () => {
    return (
        <Stack spacing={3}>
            <Text>In the bustling urban environment of Paterson, maintaining tree health can be a challenge. Urban trees
                face unique stresses, from limited soil space to pollution. However, with proper care, these green
                giants can thrive, offering a multitude of benefits to city dwellers.</Text>
            <Heading as="h3" fontSize={'xl'}>Urban Tree Care Essentials:</Heading>
            <List styleType="disc">
                <ListItem>Regular Inspection: Regularly check for signs of stress or disease. Early detection is key to
                    maintaining urban tree health.</ListItem>
                <ListItem>Appropriate Watering: Urban trees often require additional watering, especially during dry
                    spells. However, avoid over-watering as it can lead to root rot.</ListItem>
                <ListItem>Pruning: Prune trees to manage their growth, remove dead branches, and improve their form.
                    Visit our <Link href='/tree-pruning'>tree pruning</Link> service page for more details.</ListItem>
                <ListItem>Soil Management: Compacted urban soils can restrict tree growth. Aeration and adding organic
                    matter can significantly improve soil quality.</ListItem>
                <ListItem>Protection from Damage: Protect trees from physical damage caused by vehicles, construction,
                    or pedestrian traffic.</ListItem>
            </List>
            <Text>For more information on urban tree care or to schedule a consultation, visit our <Link
                href='/tree-health-consulting'>tree health consulting</Link> page. Our Paterson Tree Service experts are
                dedicated to enhancing urban tree health and beauty.</Text>
        </Stack>
    )
}